import React, { useState } from "react";
import { ColorPicker, ColorPalette } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";

const TabColorPicker = ({ type = "", onChange }) => {
    const settings = useSelector(
        (state) =>
            state?.settings?.general?.tabs[type] || { icon: "rgba(0, 0, 0, 1)", background: "rgba(255, 239, 165, 0.6)" }
    );

    return (
        <div className="d-flex flex-row text-center my-2">
            <div className="d-flex justify-content-center flex-column me-2">
                <div>Icon</div>
                <div className="mx-auto">
                    <ColorPicker
                        view="palette"
                        value={settings.icon}
                        onChange={(event) => onChange(type, "icon", event.value)}
                        defaultValue="rgba(0, 0, 0, 1))"
                        paletteSettings={{ tileSize: 32, palette: "slipstream" }}
                    />
                    {/* <ColorPalette tileSize={16} palette="slipstream" /> */}
                </div>
            </div>
            <div className="d-flex justify-content-center flex-column ms-2">
                <div>Background</div>
                <div className="mx-auto">
                    <ColorPicker
                        view="gradient"
                        value={settings.background}
                        onChange={(event) => onChange(type, "background", event.value)}
                        defaultValue="rgba(255, 239, 165, 0.6)"
                        // gradientSettings={{
                        //     opacity: false,
                        // }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TabColorPicker;
