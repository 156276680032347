import React, { createContext, useContext, useState } from "react";

const GridFilesContext = createContext();

export const GridFilesProvider = ({ children }) => {
    const [fileData, setFileData] = useState([]);

    return <GridFilesContext.Provider value={{ fileData, setFileData }}>{children}</GridFilesContext.Provider>;
};

export const useGridFiles = () => {
    const context = useContext(GridFilesContext);

    if (!context) {
        throw new Error("useGridFiles must be used within a GridFilesProvider");
    }

    return context;
};
