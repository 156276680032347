import React, { useState, useEffect } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useDispatch } from "react-redux";
import { ErrorBoundary } from "@sentry/react";

import { getSettings } from "./reducers/settings/settingsFunctions";
import { getRules } from "./reducers/rules/rulesFunctions";
import { updateInfo } from "./reducers/info/infoSlice";
import useNetwork from "./helpers/useNetwork";

import Header from "./common/components/Header";
import RoutedTabs from "./common/components/RoutedTabs";
import ScrollProgress from "./common/components/ScrollProgress";
import ProfileError from "./common/components/ProfileError";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./App.css";

function App() {
    const dispatch = useDispatch();
    const isOnline = useNetwork();
    const { userId } = useSessionContext();
    const [gotSettings, setGotSettings] = useState(false);

    useEffect(() => {
        function fetchSettings() {
            try {
                dispatch(getSettings());
                dispatch(getRules());
                setGotSettings(true);
            } catch (error) {
                console.error("Issue getting user rules and settings:", error);
            }
        }
        if (userId && gotSettings === false) {
            fetchSettings();
        }
    }, [userId, dispatch, gotSettings]);

    useEffect(() => {
        dispatch(updateInfo({ key: "appVersion", value: window.__APP_VERSION__ }));
    }, [dispatch]);

    return (
        <div className="App">
            <ScrollProgress>
                <ErrorBoundary fallback={<ProfileError />}>
                    <Header />
                    <RoutedTabs />
                </ErrorBoundary>
            </ScrollProgress>
            {!isOnline ? (
                <div className="offline-overlay">
                    <div
                        className="bubble-show justify-content-center align-items-center d-flex flex-column p-5"
                        style={{ background: "white" }}
                    >
                        <p>FoliEdge requires an internet connection, but you seem to be offline.</p>
                        <p>Something may be wrong with your connection.</p>
                        <p>Attempting to refresh the page may result in lost work.</p>
                        <p>This message will disappear when you appear to be back online.</p>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default App;
