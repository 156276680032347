import { emptyMongoApi } from "./emptyMongoApi";

const tasksAPI = emptyMongoApi.injectEndpoints({
    endpoints: (builder) => ({
        getTaskCount: builder.query({
            query: () => "tasks/count",
            providesTags: ["TaskCount"],
        }),
        getTasks: builder.query({
            query: (id) => {
                return `tasks/actionItem/${id}`;
            },
            providesTags: (result, error, arg) => (result ? [{ type: "Tasks", id: arg }] : []),
        }),
        getTask: builder.query({
            query: (id) => {
                return `tasks/${id}`;
            },
            providesTags: (result, error, arg) => (result ? [{ type: "Task", id: arg }] : []),
        }),
        updateSingleTask: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `tasks/${id}`,
                method: "PATCH",
                body: { values: patch },
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    tasksAPI.util.updateQueryData("getTask", id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
        updateTask: builder.mutation({
            query: ({ id, apiID, ...patch }) => ({
                url: `tasks/${id}`,
                method: "PATCH",
                body: { values: patch },
            }),
            async onQueryStarted({ id, apiID, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    tasksAPI.util.updateQueryData("getTasks", apiID, (draft) => {
                        let index = draft.active.findIndex((task) => task._id === id);
                        if (index !== -1) {
                            draft.active[index] = { ...draft.active[index], ...patch };
                        } else {
                            index = draft.completed.findIndex((task) => task._id === id);
                            if (index !== -1) {
                                draft.completed[index] = { ...draft.completed[index], ...patch };
                            }
                        }
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    console.log("error", error);
                    patchResult.undo();
                }
            },
        }),
        deleteTask: builder.mutation({
            query: ({ ids, apiID, socket_id }) => ({
                url: `tasks`,
                method: "DELETE",
                body: { ids: ids, socket_id },
            }),
            async onQueryStarted({ ids, apiID }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    // remove if task._id is in ids
                    tasksAPI.util.updateQueryData("getTasks", apiID, (draft) => {
                        draft.active = draft.active.filter((task) => !ids.includes(task._id));
                        draft.completed = draft.completed.filter((task) => !ids.includes(task._id));
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
    }),

    // overrideExisting: false,
});

export const {
    useGetTaskCountQuery,
    useGetTasksQuery,
    useGetTaskQuery,
    useUpdateTaskMutation,
    useUpdateSingleTaskMutation,
    useDeleteTaskMutation,
} = tasksAPI;
