import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const DotLottie = ({ src = "", speed = 1, loop = true, autoplay = true, style = { width: 350, height: 350 } }) => {
    return (
        <div style={style}>
            <DotLottieReact src={src} autoplay={autoplay} speed={speed} loop={loop} style={style} />
        </div>
    );
};

export default DotLottie;
