// import { useState } from "react";

// export const useDialogLogic = () => {
//     const [show, setShow] = useState(false);

//     const toggleShow = () => {
//         setShow((show) => !show);
//     };

//     return { show, toggleShow };
// };

import { useState, useRef } from "react";

export const useDialogLogic = () => {
    const showRef = useRef(false); // Persistent state
    const [, setShow] = useState(false); // Used to trigger re-renders

    const toggleShow = () => {
        showRef.current = !showRef.current; // Toggle visibility
        setShow((prev) => !prev); // Trigger re-render
    };

    return { show: showRef.current, toggleShow };
};
