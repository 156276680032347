import { createSlice } from "@reduxjs/toolkit";

import { updateErrorsFunc } from "./errorsFunctions";

export const errorsSlice = createSlice({
    name: "errors",
    initialState: {
        pusher: true,
    },
    reducers: {
        updateErrors: (state, action) => {
            updateErrorsFunc(state, action);
        },
    },
});

export const { updateErrors } = errorsSlice.actions;

export default errorsSlice.reducer;
