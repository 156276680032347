import { createSlice } from "@reduxjs/toolkit";

import { updateInfoFunc } from "./infoFunctions";

export const infoSlice = createSlice({
    name: "info",
    initialState: {
        socketId: "",
        appVersion: "",
    },
    reducers: {
        updateInfo: (state, action) => {
            updateInfoFunc(state, action);
        },
    },
});

export const { updateInfo } = infoSlice.actions;

export default infoSlice.reducer;
