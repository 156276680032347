import React, { createContext, useContext } from "react";

import useLocalStorage from "../helpers/useLocalStorage";

const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
    const [color, setColor] = useLocalStorage("color", { background: "#ff6347", text: "white" });

    return <ColorContext.Provider value={{ color, setColor }}>{children}</ColorContext.Provider>;
};

export const useColor = () => {
    const context = useContext(ColorContext);

    if (!context) {
        throw new Error("useColor must be used within a ColorProvider");
    }

    return context;
};
