import React, { createContext, useContext } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

import { useGetUserInfoQuery } from "../services/users";

import LoadingSuspense from "../common/components/LoadingSuspense";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const { userId } = useSessionContext();

    const {
        data = { modules: [], reports: [] },
        isLoading,
        isFetching,
    } = useGetUserInfoQuery(undefined, { skip: !userId });

    if (isLoading || isFetching) {
        return <LoadingSuspense delay={500} />;
    }

    return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
