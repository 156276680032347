import { createSlice } from "@reduxjs/toolkit";

import { initialState as defaultInitialState } from "../../providers/initialState";

import {
    add404TabFunc,
    addAndActiveFunc,
    addTabFunc,
    changeTabNameFunc,
    changeViewFunc,
    changeViewNameFunc,
    createFromViewFunc,
    createViewFunc,
    deleteTabFunc,
    deleteViewFunc,
    removeActiveTabFunc,
    saveChangesFunc,
    setActiveTabFunc,
    setViewFunc,
    updateSettingsFunc,
    closeAllTabsFunc,
    externalDeleteTabFunc,
    addTabsFunc,
} from "./newViewFunctions";

export const viewSlice = createSlice({
    name: "views",
    initialState: {
        ...defaultInitialState,
    },
    reducers: {
        createView: (state, action) => {
            createViewFunc(state, action);
        },
        createFromView: (state, action) => {
            createFromViewFunc(state, action);
        },
        setView: (state, action) => {
            setViewFunc(state, action);
        },
        changeView: (state, action) => {
            changeViewFunc(state, action);
        },
        changeViewName: (state, action) => {
            changeViewNameFunc(state, action);
        },
        deleteView: (state, action) => {
            deleteViewFunc(state, action);
        },
        saveChanges: (state, action) => {
            saveChangesFunc(state, action);
        },
        addTab: (state, action) => {
            addTabFunc(state, action);
        },
        addTabs: (state, action) => {
            addTabsFunc(state, action);
        },
        setActiveTab: (state, action) => {
            setActiveTabFunc(state, action);
        },
        removeActiveTab: (state, action) => {
            removeActiveTabFunc(state, action);
        },
        add404Tab: (state, action) => {
            add404TabFunc(state, action);
        },
        deleteTab: (state, action) => {
            deleteTabFunc(state, action);
        },
        externalDeleteTab: (state, action) => {
            externalDeleteTabFunc(state, action);
        },
        changeTabName: (state, action) => {
            changeTabNameFunc(state, action);
        },
        addAndActiveTab: (state, action) => {
            addAndActiveFunc(state, action);
        },
        updateSettings: (state, action) => {
            updateSettingsFunc(state, action);
        },
        closeAllTabs: (state, action) => {
            closeAllTabsFunc(state, action);
        },
    },
});

export const {
    createView,
    createFromView,
    setView,
    changeView,
    changeViewName,
    deleteView,
    saveChanges,
    addTab,
    addTabs,
    setActiveTab,
    removeActiveTab,
    add404Tab,
    deleteTab,
    changeTabName,
    addAndActiveTab,
    updateSettings,
    closeAllTabs,
    externalDeleteTab,
} = viewSlice.actions;

export default viewSlice.reducer;
