const initialChangesMade = {
    data: {},
    columns: [],
};

const initialDataState = {
    skip: 0,
    take: 25,
    group: [],
    sort: [],
    filter: { filters: [], logic: "and" },
};

const initialGeneralSettings = {
    showFilteredColumnsAboveGrid: false,
    gridWrapHeaders: false,
    gridWrap: false,
    gridFontSize: 14,
    gridCustomFontSize: false,
};

const initialState = {
    agreements: {
        activeTab: "grid/1",
        eventKeys: ["agreements", "agreements-1", "addAgreement-2", "renewelAgreement-3", "terminationNotice-4"],
        tabs: [
            {
                id: 1,
                component: "agreements",
                title: "All Agreements",
                currentView: "",
                type: "grid",
                eventKey: "agreements-1",
                permanent: true,
            },
            {
                id: 2,
                component: "addAgreement",
                title: "Add Agreement",
                type: "add",
                eventKey: "addAgreement-2",
                permanent: true,
            },
        ],
        tabCount: 2,
        isDeleting: false,
    },
    payments: {
        activeTab: "grid/1",
        eventKeys: ["payments", "payments-1", "transactions-2", "missingPayments-3", "addPayment-4"],
        tabs: [
            {
                id: 1,
                component: "payments",
                title: "Payments Received",
                currentView: "",
                type: "grid",
                eventKey: "payments-1",
                permanent: true,
            },
            {
                id: 2,
                component: "transactions",
                title: "Payment Transactions",
                currentView: "",
                type: "grid",
                eventKey: "transactions-2",
                permanent: true,
            },
            {
                id: 3,
                component: "missingPayments",
                title: "Payments Due",
                currentView: "",
                type: "grid",
                eventKey: "missingPayments-3",
                permanent: true,
            },
            {
                id: 4,
                component: "addPayment",
                title: "Add Payment",
                type: "add",
                eventKey: "addPayment-4",
                permanent: true,
            },
        ],
        tabCount: 4,
        isDeleting: false,
    },
    services: {
        subViews: [],
        activeTab: "grid/1",
        eventKeys: ["services", "services-1", "serviceCategory-2", "addService-3"],
        tabs: [
            {
                id: 1,
                component: "services",
                title: "All Services",
                currentView: "",
                type: "grid",
                eventKey: "services-1",
                permanent: true,
            },
            {
                id: 2,
                component: "serviceCategory",
                title: "All Service Categories",
                currentView: "",
                type: "grid",
                eventKey: "serviceCategory-2",
                permanent: true,
            },
            {
                id: 3,
                component: "addService",
                title: "Add Service",
                type: "add",
                eventKey: "addService-3",
                permanent: true,
            },
        ],
        tabCount: 3,
        isDeleting: false,
    },
    properties: {
        activeTab: "grid/1",
        eventKeys: ["properties", "properties-1", "addProperty-2"],
        tabs: [
            {
                id: 1,
                component: "properties",
                title: "All Properties",
                currentView: "",
                type: "grid",
                eventKey: "properties-1",
                permanent: true,
            },
            {
                id: 2,
                component: "addProperty",
                title: "Add Property",
                type: "add",
                eventKey: "addProperty-2",
                permanent: true,
            },
        ],
        tabCount: 2,
        isDeleting: false,
    },
    contacts: {
        activeTab: "grid/1",
        eventKeys: ["contacts", "contacts-1"],
        tabs: [
            {
                id: 1,
                component: "contacts",
                title: "All Contacts",
                currentView: "",
                type: "grid",
                eventKey: "contacts-1",
                permanent: true,
            },
            {
                id: 2,
                component: "addContact",
                title: "Add Contact",
                type: "add",
                eventKey: "addContact-2",
                permanent: true,
            },
        ],
        tabCount: 2,
        isDeleting: false,
    },
    businesses: {
        activeTab: "grid/1",
        eventKeys: ["businesses", "businesses-1", "providers-2", "addCompany-3"],
        tabs: [
            {
                id: 1,
                component: "businesses",
                title: "All Businesses",
                currentView: "",
                type: "grid",
                eventKey: "businesses-1",
                permanent: true,
            },
            {
                id: 2,
                component: "providers",
                title: "All Providers",
                currentView: "",
                type: "grid",
                eventKey: "providers-2",
                permanent: true,
            },
            {
                id: 3,
                component: "addCompany",
                title: "Add Business",
                type: "add",
                eventKey: "addCompany-3",
                permanent: true,
            },
        ],
        tabCount: 3,
        isDeleting: false,
    },
    tasks: {
        activeTab: "grid/1",
        eventKeys: ["tasks", "tasks-1", "taskList-2"],
        tabs: [
            {
                id: 1,
                component: "tasks",
                title: "All Tasks",
                currentView: "",
                type: "grid",
                eventKey: "tasks-1",
                permanent: true,
            },
        ],
        tabCount: 1,
        isDeleting: false,
    },
};

export { initialState, initialChangesMade, initialGeneralSettings, initialDataState };
