import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun as farSun, faFaceLaughBeam as farFaceLaughBeam } from "@fortawesome/free-regular-svg-icons";
import { faBed, faSun, faChampagneGlasses } from "@fortawesome/free-solid-svg-icons";

function Clock() {
    const [date, setDate] = useState(new Date());

    function refreshClock() {
        setDate(new Date());
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    };

    let day = date.toLocaleString("en-US", { weekday: options.weekday });
    let calendarDate = date.toLocaleString("en-US", { day: options.day, month: options.month, year: options.year });
    let time = date.toLocaleString("en-US", { hour: options.hour, minute: options.minute, hour12: options.hour12 });

    time = time.toLowerCase();

    let formattedDate = `${day}, ${calendarDate}, ${time}`;

    const hours = date.getHours();

    let icon = "";
    if (hours >= 6 && hours < 8) {
        icon = faBed;
    } else if (hours >= 8 && hours < 14) {
        icon = farSun;
    } else if (time === "5:00 pm") {
        icon = faChampagneGlasses;
    } else if (hours >= 14 && hours < 17) {
        icon = faSun;
    } else {
        icon = farFaceLaughBeam;
    }

    return (
        <span style={{ fontSize: "0.85rem" }}>
            {formattedDate} <FontAwesomeIcon icon={icon} fixedWidth className="ms-2" />
        </span>
    );
}
export default Clock;
