import { createSlice } from "@reduxjs/toolkit";
import { addDataFunc, removeDataFunc } from "./gridFilesFunctions";

export const gridFilesSlice = createSlice({
    name: "gridFiles",
    initialState: {
        agreements: [],
    },
    reducers: {
        addData: (state, action) => {
            addDataFunc(state, action);
        },
        removeData: (state, action) => {
            removeDataFunc(state, action);
        },
    },
});

export const { addData, removeData } = gridFilesSlice.actions;

export default gridFilesSlice.reducer;
