import { createSlice } from "@reduxjs/toolkit";
import { addRuleFunc, removeRuleFunc, updateRulesFunc, getRules } from "./rulesFunctions";

export const rulesSlice = createSlice({
    name: "rules",
    initialState: {},
    reducers: {
        updateRules: (state, action) => {
            updateRulesFunc(state, action);
        },
        addRule: (state, action) => {
            addRuleFunc(state, action);
        },
        removeRule: (state, action) => {
            removeRuleFunc(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRules.fulfilled, (state, action) => {
            if (action.payload.length === 0) {
                return state;
            }
            Object.assign(state, action.payload);
        });
        builder.addCase(getRules.rejected, (state, action) => {
            console.error("[STORE] Issue getting user rules.", action?.error);
            state.error = true;
            // return state;
        });
    },
});

export const { updateRules, addRule, removeRule } = rulesSlice.actions;

export default rulesSlice.reducer;
