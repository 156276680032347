import React, { useState } from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";

import { Popup } from "@progress/kendo-react-popup";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";

const Subscriptions = ({ items = [], setChangeMade, setSettings, settings = {}, ...props }) => {
    // const [showPopup, setShowPopup] = useState(false);
    // const [searchTerm, setSearchTerm] = useState("");
    // const [propertyList, setPropertyList] = useState(items);

    const handleSettings = (newSettings) => {
        setChangeMade(true);
        setSettings(newSettings);
    };

    const handleReadOnClose = (event) => {
        const newSettings = {
            ...settings,
            notifications: {
                readOnClose: event.value,
            },
        };
        handleSettings(newSettings);
    };

    // const handleSearchChange = (event) => {
    //     setSearchTerm(event.target.value);
    //     setShowPopup(true);
    // };

    // const handleSubscribe = (property) => {
    //     setPropertyList((prevList) => {
    //         return prevList.map((item) => {
    //             if (item.name === property.name) {
    //                 return { ...item, isSubscribed: !item.isSubscribed };
    //             }
    //             return item;
    //         });
    //     });
    // };

    // const filteredProperties = propertyList.filter((item) =>
    //     item.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    return (
        <div>
            <div className="my-3">
                <div>
                    <b>General</b>
                </div>
                <div className="my-3">
                    <Switch
                        id="mark-notifications-as-read-on-close"
                        checked={settings?.notifications?.readOnClose ? true : false}
                        onChange={handleReadOnClose}
                    />
                    <span className="ms-2">Mark notifications as read on close</span>
                </div>
            </div>
            <div style={{ borderTop: "1px solid lightgray" }} />
            {/* <div className="my-3">
                <div>
                    <b>Subscriptions</b>
                </div>
                <div className="my-3">
                    <div>No subscriptions</div>
                </div>
            </div>

            <div style={{ borderTop: "1px solid lightgray" }} /> */}

            {/* <AutoComplete
                data={propertyList.map((item) => item.name)}
                onChange={handleSearchChange}
                placeholder="Search for a property..."
            />
            {showPopup && (
                <Popup anchor={this.anchor} show={true}>
                    {filteredProperties.map((property, index) => (
                        <div key={index}>
                            <Checkbox
                                label={property.name}
                                checked={property.isSubscribed}
                                onChange={() => handleSubscribe(property)}
                            />
                        </div>
                    ))}
                </Popup>
            )} */}
        </div>
    );
};

export default Subscriptions;
