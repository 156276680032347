import React, { useState, useEffect, useCallback } from "react";

import SearchDialog from "./SearchDialog";
import Clock from "./Clock";

import "./css/GlobalSearch.css";

const GlobalSearch = () => {
    const [showGlobalSearch, setShowGlobalSearch] = useState(false);

    const toggleGlobalSearch = useCallback(() => {
        setShowGlobalSearch((showGlobalSearch) => {
            if (!showGlobalSearch) {
                document.documentElement.classList.add("no-scroll");
            } else {
                document.documentElement.classList.remove("no-scroll");
            }
            return !showGlobalSearch;
        });
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Use e.metaKey for Command (Mac) or Windows key (Windows/Linux), and e.ctrlKey for Ctrl
            if ((e.metaKey || e.ctrlKey) && e.key === "k") {
                e.preventDefault();
                toggleGlobalSearch();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [toggleGlobalSearch]);

    const isMac = window?.navigator?.userAgent?.includes("Macintosh") ?? false;

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center text-center">
                <button type="button" className="global-search-button" onClick={toggleGlobalSearch}>
                    <svg width="1em" height="1em" viewBox="0 0 20 20" className="me-3">
                        <path
                            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Search
                    <span className="ms-auto">
                        <span className="me-3">
                            <Clock />
                        </span>
                        {isMac ? <kbd className="kbd-icon">⌘</kbd> : <kbd className="kbd-icon">Ctrl</kbd>}
                        <kbd className="kbd-icon me-3">K</kbd>
                    </span>
                </button>
            </div>

            {showGlobalSearch ? (
                <div className="no-overflow">
                    <SearchDialog toggle={toggleGlobalSearch} />
                </div>
            ) : null}
        </div>
    );
};

export default GlobalSearch;
