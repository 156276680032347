import React, { useState } from "react";
import SearchItem from "./SearchItem";
import { Button as KRButton } from "@progress/kendo-react-buttons";

const ScrollItems = ({ name = "", items = [], onMouseEnter, handleToggle, selected, scrolling, scrollSource }) => {
    const [slice, setSlice] = useState(10);

    return (
        <div>
            {items.length > 0 ? (
                <div className="px-2 mb-4">
                    <header
                        className="global-search-header text-muted text-muted-small"
                        style={{ textTransform: "uppercase" }}
                    >
                        {name} ({items.length})
                    </header>
                    <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                        {items.slice(0, slice).map((item) => {
                            return (
                                <SearchItem
                                    onMouseEnter={() => onMouseEnter(item)}
                                    item={item}
                                    handleToggle={handleToggle}
                                    index={item.globalId}
                                    selected={selected}
                                    scroll={scrolling}
                                    scrollSource={scrollSource}
                                    key={`${item.type}-${item.globalId}`}
                                />
                            );
                        })}
                    </ul>
                    {items.length > slice ? (
                        <KRButton
                            className="mt-2 text-decoration-underline"
                            fillMode="link"
                            size="small"
                            onClick={() => setSlice((slice) => slice + 10)}
                        >
                            Show More {name}
                        </KRButton>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default ScrollItems;
