import { getUserInfo } from "../common/api/UserAPI";
import { emptyMongoApi } from "./emptyMongoApi";

const usersAPI = emptyMongoApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserInfo: builder.query({
            queryFn: async () => {
                try {
                    const result = await getUserInfo();
                    return { data: result };
                } catch (error) {
                    return { error: error.message };
                }
            },
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { useGetUserInfoQuery } = usersAPI;
