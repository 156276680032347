import { createSlice } from "@reduxjs/toolkit";
import { updateTaskActionsFunc } from "./commandCellFunctions";

export const commandCellSlice = createSlice({
    name: "commandCells",
    initialState: {
        taskActions: {
            in: ["Complete Task", "Snooze Task", "Edit Task"],
            out: [],
        },
        gridActions: { in: ["Attachments", "List View", "Tasks"], out: [] },
    },
    reducers: {
        updateTaskActions: (state, action) => {
            updateTaskActionsFunc(state, action);
        },
    },
});

export const { updateTaskActions } = commandCellSlice.actions;

export default commandCellSlice.reducer;
