import React from "react";

import Toast from "react-bootstrap/Toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

import "./css/SuccessToast.css";

const SuccessToast = ({ show = false, toggleShow, message = "Data saved successfully." }) => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                right: 0,
                zIndex: 10000,
            }}
        >
            <Toast
                show={show}
                onClose={toggleShow}
                className="m-2 foliedge-toast-success"
                delay={8000}
                autohide
                animation={true}
            >
                <Toast.Header>
                    <strong className="me-auto">Foliedge</strong>
                </Toast.Header>
                <Toast.Body>
                    <FontAwesomeIcon icon={faThumbsUp} style={{ color: "#5cb85c" }} className="me-3" />
                    {message}
                </Toast.Body>
            </Toast>
        </div>
    );
};

export default SuccessToast;
