import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { Hint } from "@progress/kendo-react-labels";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faBuilding as farBuilding,
    faHandshake as farHandshake,
} from "@fortawesome/free-regular-svg-icons";

import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import "./css/SearchItem.css";

const SearchItem = ({
    item,
    index,
    selected,
    handleToggle,
    onMouseEnter,
    scroll,
    itemRef = null,
    scrollSource = "keyboard",
}) => {
    const ref = useRef(null);

    useEffect(() => {
        if (scrollSource !== "mouse" && !scroll && index === selected) {
            ref.current?.scrollIntoView({ block: "center", inline: "nearest" });
            if (itemRef) {
                itemRef.current = item;
            }
        }
    }, [scroll, index, selected, item, itemRef, scrollSource]);

    return (
        <li
            id={`option-${index}`}
            className="mt-3 global-search-item"
            role="option"
            aria-selected={index === selected}
            key={index}
            onMouseEnter={scrollSource !== "keyboard" ? onMouseEnter : null}
        >
            {item.type === "property" ? (
                <Link
                    to={`/properties/view/${item.PropertyId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={farBuilding} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="global-search-result-name">{item.PropertyName}</div>
                    </div>
                </Link>
            ) : null}
            {item.type === "agreement" ? (
                <Link
                    to={`/agreements/view/${item.ContractId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={farHandshake} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name d-flex align-items-center">
                                {item.ClientNames}
                                <Hint className="text-muted text-muted-small ms-2">({item.ContractDispId})</Hint>
                            </div>
                            <div style={{ fontSize: "0.9rem" }}>{item.PropertyNames}</div>
                            <Hint className="text-muted text-muted-small">
                                {item.ProviderName} &bull; {item.ServiceCategories} &bull; {item.Services}
                            </Hint>
                        </div>
                    </div>
                </Link>
            ) : null}
            {item.type === "contact" ? (
                <Link
                    to={`/contacts/view/${item.ContactId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={faAddressBook} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.ContactName}</div>
                        </div>
                    </div>
                </Link>
            ) : null}
            {item.type === "business" ? (
                <Link
                    to={`/businesses/view/${item.CompanyId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={faBriefcase} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.CompanyName}</div>
                            <Hint>{item.CompanyType}</Hint>
                        </div>
                    </div>
                </Link>
            ) : null}
        </li>
    );
};

export default SearchItem;
