export function addDataFunc(state, action) {
    state[action.payload.module] = [...state[action.payload.module], ...action.payload.value];
    return state;
}

export function removeDataFunc(state, action) {
    state[action.payload.module] = state[action.payload.module].filter(
        (item) => item[action.payload.id] !== action.payload.value
    );
    return state;
}
